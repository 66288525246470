import logo from './logo.webp';
import './App.css';
import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.location.href = 'https://www.mangalagiripickles.com';
    }, 5000); // Redirect after 5 seconds

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>We are now <h2>  Mangalagiri Pickles</h2></p>
        <p>
          Redirecting to&nbsp;
          <a
          className="App-link"
          href="https://www.mangalagiripickles.com"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.preventDefault()}
        >
          www.mangalagiripickles.com
        </a>
        </p>
      </header>
    </div>
  );
}

export default App;
